@import "../config";

@include exports("modules/Software") {
    @include non-critical() {
        .software {
            background-image: url('../../../img/landingpage/pure-base-501-lp-background-o.jpg');
            background-position: top center;
            background-repeat: repeat-y;
            background-size: 100% auto;
            &-header {
                position: relative;
                width: 100%;
                &-content {
                    position: absolute;
                    width: 100%;
                    top: 50%;
                    bottom: auto;
                    -webkit-transform: translateY(-50%);
                    -ms-transform: translateY(-50%);
                    transform: translateY(-50%);
                    z-index: 20;
                }
                &-logo {
                    img {
                        display: block;
                        width: 320px;
                        height: auto;
                        margin: 0 auto;
                    }
                }
                span {
                    display: block;
                    font-size: 14px;
                }
                &-image {
                    position: relative;
                    z-index: 10;
                    img {
                        display: block;
                        width: 100%;
                        height: auto;
                        margin: 0 auto;
                    }
                }
            }
            &-tutorial-videos {
                background: #000;
            }
            &-hardware-with-io-center {
                background: rgb(15,15,15);
                background: linear-gradient(90deg, rgba(15,15,15,1) 0%, rgba(25,25,25,1) 100%);
            }
            &-features {
                &-box {
                    position: relative;
                    &-content {
                        position: absolute;
                        
                        display: flex;
                        justify-content: center;
                        flex-direction: column;

                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        z-index: 20;
                        &.active {
                            background-color: rgba(0,0,10,0.33);
                            justify-content: flex-start;
                        }
                        h3 {
                            text-transform: uppercase;
                            padding: 0;
                            margin: 0;
                        }
                    }
                    &-text {
                        display: none;
                        &.active {
                            display: block;
                        }
                    }
                    &-btn {
                        background: transparent;
                        padding: 0;
                    }
                    img {
                        display: block;
                        width: 100%;
                        height: auto;
                        z-index: 10;
                    }
                }
            }
            &-headline {
                display: block;
                color: #fff;
                text-transform: uppercase;
                font-weight: 800;
                font-size: 2.7777777778em;
                line-height: 1;
            }
            .btn-cta {                    
                background: rgb(235,98,18);
                background: linear-gradient(90deg, rgba(235,98,18,1) 0%, rgba(239,127,1,1) 100%);
                text-transform: uppercase;
                padding: 1rem 1.5rem;
                font-size: 20px;
                font-weight: 700;
                line-height: 100%;
                letter-spacing: normal;
                &:hover {
                    background: rgb(15,15,15);
                    background: linear-gradient(90deg, rgba(15,15,15,1) 0%, rgba(25,25,25,1) 100%);
                    color: #555;
                }
            }
            .box-border {
                border: 1px solid #777777;
                -webkit-border-radius: 4px;
                -moz-border-radius: 4px;
                border-radius: 4px;
                overflow: hidden;
            }
        }
    }
    @include critical() {
        .col-xs-auto {
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            width: auto;
            max-width: 100%;
            padding-right: 10px;
            padding-left: 10px;
        }
        @media (min-width: 768px) {
            .col-md-auto {
                -ms-flex: 0 0 auto;
                flex: 0 0 auto;
                width: auto;
                max-width: 100%;
                padding-right: 10px;
                padding-left: 10px;
            }
        }
        @media (min-width: 992px) {
            .col-lg-auto {
                -ms-flex: 0 0 auto;
                flex: 0 0 auto;
                width: auto;
                max-width: 100%;
                padding-right: 10px;
                padding-left: 10px;
            }
        }
    }
}